<template>
  <main class="md:ml-12">
    <div class="wrapper" v-if="dataLoaded">
      <div class="grid md:flex flex-row mr-4 md:mr-0 md:justify-between items-end md:mb-6">
        <ul class="flex-col mt-12">
          <li><a href="#">Customers</a></li>
          <li class="font-bold mt-4 text-2xl whitespace-no-wrap">
            Total: {{ customers.length }} customer(s)
          </li>
        </ul>
        <ul class="grid md:flex flex-row mt-4 items-end md:justify-end w-full">
          <li class="md:mr-5">
            <trac-button variant="outline" class="w-full" :disabled="true">
              IMPORT CSV</trac-button
            >
          </li>
          <li class="py-4 md:py-0">
            <trac-button
              @button-clicked="exportCustomers"
              variant="outline"
              class="w-full"
              :disabled="!readyForExport"
              >EXPORT CSV</trac-button
            >
          </li>
        </ul>
      </div>
      <!-- <div class="">
        <p class="text-xs text-gray-500 text-right my-2">
          <span class="text-red-600">*</span>
          Offline customers
        </p>
      </div> -->
      <div
        class="bg-white rounded-lg overflow-scroll big-shadow h-screen mr-4 md:mr-0"
        @scroll="checkScroll($event)"
      >
        <div class="mt-8 mb-5 mx-8 md:justify-between grid md:flex flex-row">
          <div class="flex flex-row items-center md:w-5/12">
            <div
              class="flex flex-row bg-white items-center rounded big-shadow justify-between px-2 w-full"
            >
              <span class="text-xs font-light text-primaryGray w-full">
                <input
                  class="w-full py-2 px-4 outline-none"
                  type="text"
                  name=""
                  id=""
                  placeholder="Search customer..."
                  @input="filterBySearch"
                />
                <!-- v-model.trim="searchedProduct" -->
              </span>
              <img src="../../assets/svg/Search_icon.svg" />
            </div>
            <!-- <trac-dropdown class="mr-5" :menuItems="menuItems"></trac-dropdown> -->
            <!-- <div class="flex flex-row items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8.16267"
                  cy="8.16267"
                  r="7.16267"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.116 17.116L13.2213 13.2213"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label class="ml-3 text-xs font-bold text-primaryBlue"
                >SEARCH</label
              >
            </div> -->
          </div>
          <div>
            <!-- <trac-button @button-clicked="$router.push({ name: 'CustomerLoyalty' })" class="mr-4"
              >CUSTOMER LOYALTY</trac-button
            > -->
            <trac-button @button-clicked="gotoCustomersInputPage" class="w-full mt-4 md:mt-0"
              >ADD NEW CUSTOMER</trac-button
            >
          </div>
        </div>
        <div class="mx-8 mb-8 border rounded-lg overflow-scroll">
          <table class="w-full table-auto">
            <thead class="bg-blue-100">
              <tr class="">
                <th class="text-left text-xs p-4">
                  <trac-checkbox @check="selecteAllCustomers"></trac-checkbox>
                </th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">
                  CUSTOMER NAME
                </th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">LOCATION</th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">
                  PHONE NUMBER
                </th>
                <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">EMAIL</th>
                <!-- <th class="text-left text-xs font-semibold whitespace-no-wrap p-4">BALANCE</th> -->
              </tr>
            </thead>
            <tbody v-if="sortedCustomers.length > 0">
              <tr
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                v-for="(customer, index) in showingCustomers"
                class="hover:bg-gray-200 cursor-pointer"
                :key="index"
              >
                <td class="text-xs">
                  <trac-checkbox
                    class="p-4"
                    @check="customer.isSelected = !customer.isSelected"
                    :isChecked="customer.isSelected"
                  ></trac-checkbox>
                </td>
                <td class="text-xs capitalize">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.name }}
                    <span class="text-red-900 bg-red-300 p-1 rounded" style="font-size:0.4rem" v-if="!customer._id"
                      >not synced</span
                    >
                  </div>
                </td>
                <td class="text-xs capitalize">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.location }}
                  </div>
                </td>
                <td class="text-xs">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.phone }}
                  </div>
                </td>
                <td class="text-xs lowercase">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.email }}
                  </div>
                </td>
                <!-- <td class="text-xs">
                  <div class="p-4" @click="openCustomerDetails(customer)">
                    {{ customer.balance | formatPrice(false) }}
                  </div>
                </td> -->
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7">
                  <trac-center-data>
                    <div class="h-40 flex items-center text-lg text-gray-600">
                      {{
                        searchedData.length > 0
                          ? customers.length > 0
                            ? "No match found."
                            : "No customer added yet."
                          : "No customer added yet."
                      }}
                    </div>
                  </trac-center-data>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="flex justify-center pb-10 pt-3 items-center w-full">
            <pagination
              class=" mt-4 mx-auto"
              v-if="paginated"
              v-model="page"
              :records="sortedCustomers.length"
              :per-page="10"
            />
          </div> -->
        </div>
      </div>
    </div>
    <div class="w-full h-screen" v-else>
      <trac-loading />
    </div>
  </main>
</template>

<script>
import Pagination from "vue-pagination-2";
import {
  DELETE_LOCAL_DB_DATA,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";

import { eventBus } from "./../../main";

export default {
  name: "Customers",
  data() {
    return {
      customers: null,
      showingCustomers: [],
      filteredCustomers: null,
      dataLoaded: false,
      searchedData: "",
      menuItems: [
        { name: "filter1", value: 1 },
        { name: "filter2", value: 2 },
        { name: "filter3", value: 3 },
        { name: "filter4", value: 4 },
      ],
      page: 1,
    };
  },
  components: {
    Pagination,
  },
  async created() {
    DELETE_LOCAL_DB_DATA("customer-details");
    await this.fetchAllCustomers();

    // Setting customers to show an initial amount
    this.showingCustomers = this.sortedCustomers.slice(0, 30);

    this.dataLoaded = true;
  },
  computed: {
    // paginated() {
    //   if (this.sortedCustomers.length > 100) {
    //     let start = parseInt((this.page - 1) * 10);
    //     let end = parseInt(this.page * 10);
    //     return this.sortedCustomers.slice(start, end);
    //   } else {
    //     return this.sortedCustomers;
    //   }
    // },
    sortedCustomers() {
      return this.filteredCustomers.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
    readyForExport() {
      return this.filteredCustomers.find((customer) => customer.isSelected);
    },
  },
  methods: {
    checkScroll(e) {
      const myDiv = e.target;
      const shownCustomersSofar = this.showingCustomers;

      if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
        if (shownCustomersSofar.length === this.customers.length) {
        } else {
          this.showingCustomers.push(
            ...this.sortedCustomers.slice(
              shownCustomersSofar.length,
              shownCustomersSofar.length + 10
            )
          );
        }
      }
    },
    filterBySearch(e) {
      const value = e.target.value;
      this.searchedData = value;
      // Removed a condition
      if (1) {
        // (value);
        this.filteredCustomers = this.customers.filter((customer) => {
          return customer.name.toLowerCase().includes(value.toLowerCase().trim()) || (customer.email || '').toLowerCase().includes(value.toLowerCase().trim()) || (customer.phone || '').includes(value.trim());
        });

        this.showingCustomers = this.filteredCustomers.slice(0, 30);
        // console.log(this.showingCustomers);
      }
    },
    gotoCustomersInputPage() {
      this.$router.push({ name: "AddCustomer" });
    },
    openCustomerDetails(customer) {
      SAVE_LOCAL_DB_DATA("customer-details", customer);
      this.$router.push({ name: "CustomerDetails" });
    },
    selecteAllCustomers(e) {
      this.customers.forEach((customer) => (customer.isSelected = e.checked));
    },
    exportCustomers() {
      {
        let csv = "Firstname,Lastname,Email,Phone,Street,City,State,Country\n";
        const listToexport = this.customers.filter(
          (customer) => customer.isSelected
        );

        listToexport.forEach((customer) => {
          csv += `${customer.first_name},${customer.last_name},${
            customer.email
          },${customer.phone},${customer.address.street || ""},${
            customer.address.city || ""
          },${customer.address.state || ""},${
            customer.address.country || ""
          }\n`;
        });

        const hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = "traction_customers.csv";
        hiddenElement.click();
      }
    },
    async fetchAllCustomers() {
      // Fetch all customers
      await this.$store.dispatch("FETCH_ALL_CUSTOMERS");
      const res = this.$store.getters["GET_ALL_CUSTOMERS"];

      if (res.status) {
        const customersData = res.data ? res.data.items || [] : [];
        this.customers = customersData.map((customer) => {
          return {
            _id: customer._id,
            offline_cust_id: customer.offline_cust_id || false,
            is_offline_data: customer.is_offline_data || false,
            day_month: customer.day_month || "",
            notes: customer.notes,
            is_offline_data: customer.is_offline_data,
            name:
              (customer.first_name || "") + " " + (customer.last_name || ""),
            first_name: customer.first_name,
            last_name: customer.last_name,
            location:
              customer.address.city || "" + " " + customer.address.state
                ? customer.address.state
                : "",
            address: customer.address,
            birthday: customer.birthday,
            phone: customer.phone,
            email: customer.email,
            balance: 0,
            isSelected: false,
          };
        });

        this.filteredCustomers = this.customers;
      } else {
        eventBus.$emit("trac-alert", { message: res.message });
      }

      // console.log(this.customers.length);

      // await this.collateCustomerBalance();
    },
    async collateCustomerBalance() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      let invoices = this.$store.getters["GET_ALL_INVOICES"].data || [];
      invoices = invoices.invoice ? invoices.invoice : [];

      this.customers.forEach((customer) => {
        customer.balance = 0;
        // Loop through all invoices in the business
        invoices.forEach((invoice) => {
          if (customer._id === invoice.client) {
            customer.balance +=
              isNaN(+invoice.balance.outstanding.amount) ||
              +invoice.balance.outstanding.amount === undefined ||
              +invoice.balance.outstanding.amount === null
                ? 0
                : +invoice.balance.outstanding.amount;
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
